import Button from "../components/button/Button"
import FAQItem from "../components/questions/Questions"
import styles from "./ThirdScreen.module.css"

const ThirdScreen=()=>{
 
return <>
   <div className={styles.title}>אתן שואלות, אני עונה</div>
   <FAQItem question="יש המון תחרות בתחום, איך דווקא אני אבלוט מעל כולן?" answer="כמו שיש הרבה בנות שעושות - יש הרבה ביקוש, תמיד יהיה ביקוש , תמיד יהיו לקוחות ויש מספיק לכולם! כל מה שאת צריכה לעשות זה להתמיד ולהביא את הלקוחות לאט לאט עם הזמן"/>
   <FAQItem question="אין לי נסיון בתחום בכלל, הקורס עדיין בשבילי?" answer="ברור! למזלך,הידע והכשרון נצברים עם הזמן והחומר שתלמדי בקורס מקיף את כל מה שאת צריכה לדעת כדי להתחיל להתפרנס מהתחום בסיום הקורס! ככל שתתמידי יותר תתחילי לתפוס את הטכניקות והידע גם אם הגעת עם 0 נסיון"/>
<FAQItem question="מה קורה איתי יום אחרי הקורס?" answer="מהרגע שאת מקבלת ממני את תעודת הסיום את יכולה להתחיל לעסוק בתחום ולהתפרנס ממנו! ועדיין, לכל שאלה,התייעצות,בעיה,חשש או אתגר - אני כאן! זמינה אלייך ותומכת בך"/>
<FAQItem question="כמה תלמידות נמצאות בכל קורס?" answer="אני מתחייבת לעד 2 בנות בקורס ביחס הכי אישי שיש!"/>
<Button text="לורין יש לי עוד כמה שאלות"/>
</>


}
export default ThirdScreen