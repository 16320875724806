import React from 'react';
import styles from "./ForthScreen.module.css";

const ForthScreen = () => {


  return <>
    
  </>

}

export default ForthScreen;